import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { BodyWrapper } from '../components/BodyWrapper';
import { FetchFromApi } from '../utils/Fetch';
import PodscribePlayer from '../components/PodscribePlayer';
import { SetHomeTitle, SetTitle } from '../utils/Title';
import PingPageView from '../utils/Analytics';

interface EpisodeParams {
  podcastId: string
  episodeId: string
}

interface EpisodeState {
  error: any
  isLoaded: boolean
  episode?: Episode
}

interface Episode {
  podcastId: string
  podcastTitle: string
  podcastLogoUrl: string
  id: number
  title: string
  description: string
  uploadTime: string
  duration: string
  transcription: Blurb[]
}

interface Blurb {
  speaker: string
  paragraphs: string[]
}

export default class EpisodePage extends React.Component<RouteComponentProps<EpisodeParams>> {
  state: EpisodeState = {
    error: null,
    isLoaded: false,
  }

  componentDidMount() {
    const params = this.props.match.params
    FetchFromApi(`podcast/${params.podcastId}/episode/${params.episodeId}`)
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            episode: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error: error
          });
        }
      )
      .catch(console.log)
  }

  render() {
    SetHomeTitle()
    PingPageView()
    const state = this.state
    const episode = state.episode!
    if (!state.isLoaded) {
      return <BodyWrapper>Loading...</BodyWrapper>;
    } else if (state.error) {
      return (
        <BodyWrapper>
          Something went wrong. You lost?<br /><br />
          Error: {state.error.message}
        </BodyWrapper>
      )
    } else {
      SetTitle(episode.title)
      var transcription = <p>No transcription for this one :(</p>
      if (episode.transcription.length > 0) {
        transcription = (
          <span>
            {episode.transcription.map((blurb, blurbIndex) => (
              <p key={blurbIndex}>
                <b>Speaker: {blurb.speaker}</b><br />
                {blurb.paragraphs.map((paragraph, paraIndex) => (
                  <span key={paraIndex}>{paragraph}<br /><br /></span>
                ))}
              </p>
            ))}
          </span>
        )
      }

      const podcastUri = `/${episode.podcastId}`
      return (
        <BodyWrapper>
          <Link to={podcastUri}>
            <img src={episode.podcastLogoUrl} alt="" />
          </Link>
          <h2>
            <Link to={podcastUri} >
              {episode.podcastTitle}
            </Link>
          </h2>
          <h1>{episode.title}</h1>
          <p>
            <i>{episode.description}</i><br />
            <br />
            {episode.uploadTime}
          </p>
          <hr />
          <div>{transcription}</div>
          <hr />
          <PodscribePlayer episodeId={episode.id} />
        </BodyWrapper>
      )
    }
  };
};
