import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { BodyWrapper } from '../components/BodyWrapper';
import SearchBar from '../components/SearchBar';
import { LinkList } from '../components/LinkList';
import { FetchFromApi } from '../utils/Fetch';
import styled from 'styled-components';
import { SetHomeTitle, SetTitle } from '../utils/Title';
import PingPageView from '../utils/Analytics';

interface PodcastParams {
  podcastId: string
}

interface PodcastState {
  error: any
  isLoaded: boolean
  podcast?: Podcast
}

interface Podcast {
  id: string
  author: string
  title: string
  description: string
  logoUrl: string
  episodes: EpisodeMetadata[]
}

interface EpisodeMetadata {
  id: number
  title: string
}

export default class PodcastPage extends React.Component<RouteComponentProps<PodcastParams>> {
  state: PodcastState = {
    error: null,
    isLoaded: false,
  }

  componentDidMount() {
    FetchFromApi(`podcast/${this.props.match.params.podcastId}`)
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            podcast: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error: error
          });
        }
      )
      .catch(console.log)
  }

  render() {
    SetHomeTitle()
    PingPageView()
    const state = this.state
    if (!state.isLoaded) {
      return <BodyWrapper>Loading...</BodyWrapper>;
    } else if (state.error) {
      return (
        <BodyWrapper>
          Something went wrong. You lost?<br /><br />
          Error: {state.error.message}
        </BodyWrapper>
      )
    } else {
      const podcast = state.podcast!
      SetTitle(podcast.title)
      const episodes = (
        podcast.episodes.map((episode) => (
        <li key={episode.id}>
          <Link to={`/${podcast.id}/${episode.id}`} >
            {episode.title}
          </Link>
        </li>
      )));

      return (
        <BodyWrapper>
          <img src={podcast.logoUrl} alt="" />
          <h2>{podcast.title}</h2>
          <i>{podcast.description}</i>
          <SearchWrapper>
            <SearchBar podcastId={this.props.match.params.podcastId} />
          </SearchWrapper>
          <LinkList>{episodes}</LinkList>
        </BodyWrapper>
      );
    }
  };
};

const SearchWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`