import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { GetLocalSearchUri } from '../utils/Fetch';

interface SearchProps {
  podcastId: string
}

interface SearchState {
  query: string,
  initiateQuery: boolean
}

export default class SearchBar extends React.Component<SearchProps, SearchState> {
  constructor(props: SearchProps) {
    super(props);
    this.state = {
      query: "",
      initiateQuery: false
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      query: event.target.value,
      initiateQuery: false
    })
  }

  handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    this.setState({
      query: this.state.query,
      initiateQuery: true
    })
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <input type="text" id="search" onChange={this.handleChange} />
          <input type="submit" value="Search Podcast" />
        </form>
        {this.state.initiateQuery && (
          <Redirect to={GetLocalSearchUri(this.props.podcastId, this.state.query)} />
        )}
      </div>
    )
  }
}
