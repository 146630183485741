import * as React from 'react';
import styled from 'styled-components';

interface PodscribePlayerProps {
  episodeId: number
}

export default class PodscribePlayer extends React.Component<PodscribePlayerProps> {
  componentDidMount() {
    const varsScript = document.createElement('script')
    varsScript.innerHTML = `var embedVars = { epId: ${this.props.episodeId}, backgroundColor: 'white', height: '600px', showSpeakers: true };`
    const script = document.createElement('script')
    script.src = "https://app.podscribe.ai/static/js/embed.js"
    document.body.appendChild(varsScript)
    document.body.appendChild(script)
  }

  render() {
    return (
      <Wrapper id="podscribe-embed-root" />
    )
  }
}

const Wrapper = styled.div`
  margin-top: 20px;
`
