import * as React from 'react';
import { BodyWrapper } from '../components/BodyWrapper';
import { SetTitle } from '../utils/Title';
import PingPageView from '../utils/Analytics';

export default class Page404 extends React.Component {
  render() {
    SetTitle('404')
    PingPageView()
    return (
      <BodyWrapper>
        <p>You lost?</p>
      </BodyWrapper>
    )
  }
};
