import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from './components/Header';
import EpisodePage from './pages/EpisodePage';
import PodcastsPage from './pages/PodcastsPage';
import PodcastPage from './pages/PodcastPage';
import SearchPage from './pages/SearchPage';
import Page404 from './pages/Page404';

class App extends React.Component {
  render() {
    return (
      <Router>
        <Header />
        <Switch>
          <Route exact path="/:podcastId/search" component={SearchPage} />
          <Route exact path="/:podcastId/:episodeId" component={EpisodePage} />
          <Route exact path="/index.html" component={PodcastsPage} />
          <Route exact path="/:podcastId" component={PodcastPage} />
          <Route exact path="/" component={PodcastsPage} />
          <Route path="/" component={Page404} />
        </Switch>
      </Router>
    );
  }
}

export default App;
