import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { BodyWrapper } from '../components/BodyWrapper';
import { FetchFromApi, GetApiSearchUri } from '../utils/Fetch';
import queryString from 'query-string';
import { SetHomeTitle, SetTitle } from '../utils/Title';
import PingPageView from '../utils/Analytics';

interface SearchParams {
  podcastId: string
}

interface SearchQueryParams {
  q: string
}

interface SearchState {
  error: any
  isLoaded: boolean
  searchResults?: SearchResults
}

interface SearchResults {
  podcastTitle: string
  podcastLogoUrl: string
  hits: Hit[]
}

interface Hit {
  episodeId: string
  episodeTitle: string
  uploadTime: string
  snippets: string[]
}

function Results(query: string, podcastId: string, results: SearchResults) {
  const podcastHeader = (
    <div>
      <Link to={`/${podcastId}`}>
        <img src={results.podcastLogoUrl} alt="" />
      </Link>
      <h3>
        <Link to={`/${podcastId}`}>{results.podcastTitle}</Link>
      </h3>
    </div>
  )

  var q = <p>No search results for "<i>{query}</i>"</p>
  if (results.hits.length > 0) {
    q = <p>Search results for "<i>{query}</i>"</p>
  }

  const resultList = (
    results.hits.map((hit, resultIndex) => (
      <div key={resultIndex}>
        <h4><Link to={`/${podcastId}/${hit.episodeId}`}>{hit.episodeTitle}</Link></h4>
        <div>
          {hit.snippets.map((snippet, snippetIndex) => (
            <div key={snippetIndex}><span dangerouslySetInnerHTML={{ __html: snippet }}></span><br /></div>
          ))}
        </div>
      </div>
    ))
  )

  if (results.hits.length > 0) {
    return (
      <div>
        {podcastHeader}
        {q}
        <hr />
        {resultList}
      </div>
    )
  } else {
    return (
      <div>
        {podcastHeader}
        {q}
      </div>
    )
  }
}

export default class PodcastsPage extends React.Component<RouteComponentProps<SearchParams>> {
  state: SearchState = {
    error: null,
    isLoaded: false
  }

  getPodcastId(): string {
    return this.props.match.params.podcastId
  }

  getQuery(): string {
    const queryParams = queryString.parse(this.props.location.search)
    // @ts-ignore
    return queryParams.q
  }

  componentDidMount() {
    FetchFromApi(GetApiSearchUri(this.getPodcastId(), this.getQuery()))
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            searchResults: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error: error
          });
        }
      )
      .catch(console.log)
  }

  render() {
    SetHomeTitle()
    PingPageView()
    var state = this.state
    if (!state.isLoaded) {
      return <BodyWrapper>Loading...</BodyWrapper>
    } else if (state.error) {
      return <BodyWrapper>Error: {state.error.message}</BodyWrapper>
    } else {
      SetTitle(this.getQuery())
      return (
        <BodyWrapper>
          {Results(this.getQuery(), this.getPodcastId(), state.searchResults!)}
        </BodyWrapper>
      )
    }
  }
}
