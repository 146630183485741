import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '../utils/Colors';

export default class Header extends React.Component {
  render() {
    return (
      <Wrapper>
        <a href="/">
          <LogoImg src="/podlib_logo.png" alt="Podlib.io" />
        </a>
      </Wrapper>
    );
  }
};

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  margin: 0px;
  height: 75px;
  background-color: ${Colors.darkGrey};
  z-index: 10;
`

const LogoImg = styled.img`
  display: block;
  height: 100%;
  margin: 0 auto;
`
