import * as React from 'react';
import { Link } from 'react-router-dom';
import { BodyWrapper } from '../components/BodyWrapper';
import { LinkList } from '../components/LinkList';
import styled from 'styled-components';
import { FetchFromApi } from '../utils/Fetch';
import { SetHomeTitle } from '../utils/Title';
import PingPageView from '../utils/Analytics';

interface PodcastsState {
  error: any
  isLoaded: boolean
  podcasts: PodcastSeries[]
}

interface PodcastSeries {
  key: string
  name: string
}

function PodcastList(state: PodcastsState) {
  if (!state.isLoaded) {
    return <div>Loading...</div>
  } else if (state.error) {
    return <div>{state.error.message}</div>
  } else {
    return (
      <div>
        <LinkList>
          {state.podcasts.map((podcast) => (
            <li key={podcast.key}>
              <Link to={`/${podcast.key}`}>
                {podcast.name}
              </Link>
            </li>
          ))}
        </LinkList>
      </div>
    );
  }
}

export default class PodcastsPage extends React.Component {
  state: PodcastsState = {
    error: null,
    isLoaded: false,
    podcasts: []
  }

  componentDidMount() {
    FetchFromApi('podcast')
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            podcasts: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error: error
          });
        }
      )
      .catch(console.log)
  }

  render() {
    SetHomeTitle()
    PingPageView()
    return (
      <BodyWrapper>
        <p>Podlib is a free, simple, and searchable Podcast transcription library.</p>
        {PodcastList(this.state)}
        <Footer>
          <DetailText>
            <i>
              Want to request a Podcast for the library?<br />
              Want higher-quality transcriptions for your Podcast?<br />
            </i>
              Email us at <a href ="mailto:podlib@protonmail.com">podlib@protonmail.com</a>
          </DetailText>
          <DetailText>
            Powered by <a target="_blank" rel="noopener noreferrer" href="https://app.podscribe.ai">Podscribe</a>
            </DetailText>
        </Footer>
      </BodyWrapper>
    )
  }
}

const Footer = styled.div`
  margin-top: 25px;
`
const DetailText = styled.p`
  font-size: 12px;
`;
