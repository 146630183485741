export function FetchFromApi(relativePath: string): Promise<JSON> {
  return fetch(`https://api.podlib.io/${relativePath}`)
    .then(
      (response) => {
        if (response.ok) {
          return response.json()
        } else {
          return response.text().then(txt => Promise.reject(new Error(txt)))
        }
      }
    )
}

export function GetApiSearchUri(podcastId: string, query: string) {
  return `search?pod=${podcastId}&q=${query}`
}

export function GetLocalSearchUri(podcastId: string, query: string) {
  return `/${podcastId}/search?q=${query}`
}
